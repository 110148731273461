import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Home() {
    const [inputValue, setInputValue] = useState('');
    const [answers, setAnswers] = useState({ frontend: '', backend: '', deployment: '', os: '' });
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleAnswerChange = (e) => {
        const { name, value } = e.target;
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [name]: value
        }));
    };

    const validateForm = () => {
        const newErrors = {};
        if (!inputValue) newErrors.inputValue = '아이디어를 입력해주세요.';
        if (!answers.frontend) newErrors.frontend = '프론트 프레임워크를 선택해주세요.';
        if (!answers.backend) newErrors.backend = '백엔드 프레임워크를 선택해주세요.';
        if (!answers.deployment) newErrors.deployment = '배포 플랫폼을 선택해주세요.';
        if (!answers.os) newErrors.os = '운영체제를 선택해주세요.';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        }

        try {
            const answerResponse = await axios.post('http://localhost:8000/result', { idea: inputValue, ...answers });
            console.log('Server response:', answerResponse.data);
            navigate('/result', { state: answerResponse.data });
        } catch (error) {
            console.error('Error submitting answers:', error);
        }
    };

    return (
        <div className="min-h-screen flex flex-col">
            <div className="flex flex-1 justify-center">
                <main className="p-8 text-center w-1/2">
                    <div className="text-5xl font-bold mb-8 mt-8 dark:text-white">Baro GO</div>
                    <div className="text-2xl font-bold mb-8 dark:text-white">아이디어</div>
                    <form onSubmit={handleSubmit} className="relative inline-flex w-full max-w-full mb-4 flex flex-col space-y-4">
                        <div className="relative inline-flex w-full">
                            <input
                                type="text"
                                placeholder="아이디어를 간단하게 한줄로 입력"
                                value={inputValue}
                                onChange={handleInputChange}
                                className={`w-full p-4 border-2 rounded-l rounded-r-none ${errors.inputValue ? 'border-red-500' : ''}`}
                                style={{ fontSize: '1.25rem' }}
                            />
                            {errors.inputValue && <p className="text-red-500">{errors.inputValue}</p>}
                        </div>
                        <label htmlFor="frontend" className="block mb-2 text-lg font-medium text-gray-900 dark:text-white">프론트</label>
                        <select
                            id="frontend"
                            name="frontend"
                            value={answers.frontend}
                            onChange={handleAnswerChange}
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.frontend ? 'border-red-500' : ''}`}
                        >
                            <option value="">프레임워크 선택</option>
                            <option value="React">React</option>
                            <option value="Vue">Vue</option>
                            <option value="Solid">Solid</option>
                            <option value="Svelte">Svelte</option>
                        </select>
                        {errors.frontend && <p className="text-red-500">{errors.frontend}</p>}
                        <label htmlFor="backend" className="block mb-2 text-lg font-medium text-gray-900 dark:text-white">백엔드</label>
                        <select
                            id="backend"
                            name="backend"
                            value={answers.backend}
                            onChange={handleAnswerChange}
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.backend ? 'border-red-500' : ''}`}
                        >
                            <option value="">프레임워크 선택</option>
                            <option value="Node">Node</option>
                            <option value="Django">Django</option>
                            <option value="FastAPI">FastAPI</option>
                            <option value="Flask">Flask</option>
                        </select>
                        {errors.backend && <p className="text-red-500">{errors.backend}</p>}
                        <label htmlFor="deployment" className="block mb-2 text-lg font-medium text-gray-900 dark:text-white">배포</label>
                        <select
                            id="deployment"
                            name="deployment"
                            value={answers.deployment}
                            onChange={handleAnswerChange}
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.deployment ? 'border-red-500' : ''}`}
                        >
                            <option value="">플랫폼 선택</option>
                            <option value="AWS">AWS</option>
                            <option value="GCP">GCP</option>
                            <option value="Cloudflare">Cloudflare</option>
                        </select>
                        {errors.deployment && <p className="text-red-500">{errors.deployment}</p>}
                        <label htmlFor="os" className="block mb-2 text-lg font-medium text-gray-900 dark:text-white">운영체제</label>
                        <select
                            id="os"
                            name="os"
                            value={answers.os}
                            onChange={handleAnswerChange}
                            className={`bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${errors.os ? 'border-red-500' : ''}`}
                        >
                            <option value="">운영체제 선택</option>
                            <option value="mac">Mac</option>
                            <option value="window">Window</option>
                            <option value="ubuntu">Ubuntu</option>
                        </select>
                        {errors.os && <p className="text-red-500">{errors.os}</p>}
                        <button
                            type="submit"
                            className="bg-black text-white p-4 rounded shadow-lg hover:bg-gray-800 transition duration-300 ease-in-out"
                        >
                            제출
                        </button>
                    </form>
                </main>
            </div>
        </div>
    );
}

export default Home;
