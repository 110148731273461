import React from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    return (
        <nav className="bg-gray-800 p-4">
            <div className="container mx-auto">
                <Link to="/" className="text-white px-2">Home</Link>
                <Link to="/about" className="text-white px-2">About</Link>
                <Link to="/contact" className="text-white px-2">Contact</Link>
            </div>
        </nav>
    );
}

export default Navbar;
