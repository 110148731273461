import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-gray-800 p-4 mt-auto">
            <div className="container mx-auto text-center text-white">
                <p>&copy; 2024 Your Company Name. All rights reserved.</p>
                <div className="mt-2">
                    <a href="/privacy" className="text-gray-400 hover:text-white px-2">Privacy Policy</a>
                    <a href="/terms" className="text-gray-400 hover:text-white px-2">Terms of Service</a>
                    <a href="/contact" className="text-gray-400 hover:text-white px-2">Contact Us</a>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
