import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Result = () => {
    const [steps, setSteps] = useState([]);
    const location = useLocation();

    useEffect(() => {
        if (location.state) {
            setSteps(location.state['steps']);
        }
    }, [location.state]);

    return (
        <>
            {console.log(steps)}
            <div className="max-h-screen flex overflow-hidden">
                <div className="w-2/5 bg-gray-100 p-4 overflow-auto">
                    <div className="mt-4 p-4 border rounded bg-gray-100 text-left">
                        <h2 className="font-bold text-xl mb-4">1. 프로젝트 개요</h2>
                        <div className="mb-4">
                            <h3 className="font-bold">1.1 프로젝트 이름</h3>
                            <p>- 프로젝트 이름: </p>
                        </div>
                        <div className="mb-4">
                            <h3 className="font-bold">1.2 프로젝트 설명</h3>
                            <p>- 프로젝트 설명: </p>
                        </div>
                        <div className="mb-4">
                            <h3 className="font-bold">1.3 목표</h3>
                            <p>- 단기 목표: </p>
                            <p>- 중기 목표: </p>
                            <p>- 장기 목표: </p>
                        </div>

                        <h2 className="font-bold text-xl mb-4">2. 시장 조사</h2>
                        <div className="mb-4">
                            <h3 className="font-bold">2.1 타겟 고객 분석</h3>
                            <p>- 타겟 고객: </p>
                        </div>
                        <div className="mb-4">
                            <h3 className="font-bold">2.2 경쟁 분석</h3>
                            <p>- 경쟁사 및 차별점: </p>
                        </div>

                        <h2 className="font-bold text-xl mb-4">3. 솔루션</h2>
                        <div className="mb-4">
                            <h3 className="font-bold">3.1 주요 기능</h3>
                            <p>- 기능 1:</p>
                            <p>- 기능 2:</p>
                        </div>

                        <h2 className="font-bold text-xl mb-4">4. 비즈니스 모델</h2>
                        <div className="mb-4">
                            <h3 className="font-bold">4.1 수익 모델</h3>
                            <p>- 수익 모델:</p>
                        </div>
                        <div className="mb-4">
                            <h3 className="font-bold">4.2 가격 전략</h3>
                            <p>- 가격 전략:</p>
                        </div>

                        <div className="mb-4">
                            <h2 className="font-bold text-xl mb-4">5. 마케팅 전략</h2>
                            <p></p>
                        </div>
                        <h2 className="font-bold text-xl mb-4">6. 개발 계획</h2>
                        <p></p>
                        <div className="mb-4">
                            <h3 className="font-bold">6.1 프로젝트 일정</h3>
                            <p>- 개발 단계:</p>
                            <ul className="list-disc ml-8">
                                <li>1단계:</li>
                                <li>2단계:</li>
                                <li>3단계:</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="w-3/5 flex flex-col">
                    <div className="bg-white p-4 overflow-auto">
                        <div className="mt-4 p-4 border rounded bg-gray-100 text-left">
                            <header className="bg-blue-600 text-white p-4 rounded mb-6 shadow">
                                <h1 className="text-2xl font-bold">React 프로젝트 설정 가이드</h1>
                            </header>
                            <main>
                                {steps.map((step, index) => (
                                    <div key={index} className="bg-white p-6 rounded shadow mb-6">
                                        <h2 className="text-xl font-semibold mb-2">Step {step.step}</h2>
                                        <p className="mb-2">{step.description}</p>
                                        {step.details && typeof step.details === 'string' && <p className="mb-2">{step.details}</p>}
                                        {step.details && typeof step.details === 'object' && (
                                            <ul className="list-disc list-inside mb-2">
                                                {Object.entries(step.details).map(([key, value], idx) => (
                                                    <li key={idx}>
                                                        <strong>{key}:</strong> {value}
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                        {step.command && <pre className="bg-gray-200 p-2 rounded mb-2"><code>{step.command}</code></pre>}
                                        {step.file && <p className="mb-2"><strong>File:</strong> {step.file}</p>}
                                        {step.code && <pre className="bg-gray-200 p-2 rounded"><code>{step.code}</code></pre>}
                                    </div>
                                ))}
                            </main>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Result;
